import slugify from "./slugify";

export default function richtextParse(richtext, drupalUrl) {
  let html = richtext
    .replace(/src="/g, `src="${drupalUrl}`)
    .replace(/<p><img/g, '<p class="image-wrapper"><img');
  let anchors = [];
  if (html && html.length) {
    let split_html = html.split(/<h2\b[^>]*>/);
    for (const block_string of split_html) {
      let block_parts = block_string.split(/<\/h2\b[^>]*>/);
      let title = block_parts.length === 2 ? block_parts[0] : "";
      if (title.length) {
        let id = slugify(title);
        html = html.replace(`>${title}</h2`, ` id="${id}">${title}</h2`);
        anchors.push({
          title,
          id,
        });
      }
    }
  } else {
    html = null;
  }
  return { html, anchors };
}
