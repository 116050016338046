/* eslint-disable import/prefer-default-export, react/prop-types */
import "../../src/styles/index.scss";
import React from "react";
import TopLayout from "./TopLayout";
import PageWrap from "./PageWrap";

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};

export const wrapPageElement = ({ element, props }) => {
  return <PageWrap element={element} props={props} />;
};
