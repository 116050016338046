import React from "react";
// import { UserContext } from "@parallelpublicworks/entitysync";
import { StaticQuery, graphql } from "gatsby";
import CurrentUserEntity from "../components/EntitySync/CurrentUserEntity";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import mergeNodes from "../data/mergeNodes";
import navDataFormat from "../data/navDataFormat";

export const ProviderContext = React.createContext({
  allNodeRefs: [],
});

function Main({ children, pageTitle, backPath }) {
  // const userContext = React.useContext(UserContext);

  return (
    <CurrentUserEntity type="user__user" componentId="bpfd-user">
      <StaticQuery
        query={graphql`
          query {
            mainMenu: allMenuItems(filter: { menu_name: { eq: "main" } }) {
              edges {
                node {
                  menu_name
                  url
                  title
                  id
                  childrenMenuItems {
                    title
                    url
                    id
                    route {
                      parameters {
                        node
                      }
                    }
                  }
                  parent {
                    id
                  }
                  route {
                    parameters {
                      node
                    }
                  }
                }
              }
            }
            secondaryMenu: allMenuItems(
              filter: { menu_name: { eq: "secondary-navigation" } }
            ) {
              edges {
                node {
                  menu_name
                  url
                  title
                  id
                  childrenMenuItems {
                    title
                    url
                    id
                    route {
                      parameters {
                        node
                      }
                    }
                  }
                  parent {
                    id
                  }
                  route {
                    parameters {
                      node
                    }
                  }
                }
              }
            }
            articles: allNodeArticle {
              nodes {
                drupal_internal__nid
                fields {
                  slug
                }
              }
            }
            pages: allNodePage {
              nodes {
                drupal_internal__nid
                fields {
                  slug
                }
              }
            }
            meetings: allNodeMeeting {
              nodes {
                drupal_internal__nid
                fields {
                  slug
                }
              }
            }
          }
        `}
        render={(data) => {
          const allNodeRefs = mergeNodes([data.articles, data.pages, data.meetings]);
          const navData = navDataFormat(data.mainMenu, allNodeRefs);
          const secondaryNavData = navDataFormat(data.secondaryMenu, allNodeRefs);
          // let login_btn = isLoggedIn([userContext])
          //   ? { title: "Logout", path: "/logout", key: "user" }
          //   : { title: "Login", path: "/login", key: "user" };

          // let OriginalSecondaryNavData = secondaryNavData.filter(
          //   (item) => (item.key && item.key !== "user") || !item.key
          // );
          // OriginalSecondaryNavData.push(login_btn);
          return (
            <ProviderContext.Provider
              value={{
                allNodeRefs,
              }}
            >
              <>
                <Navbar navData={navData} secondaryNavData={secondaryNavData} />
                <main role="main">
                  {pageTitle && <PageHeader pageTitle={pageTitle} backPath={backPath} />}
                  {children}
                </main>
                <Footer />
              </>
            </ProviderContext.Provider>
          );
        }}
      />
    </CurrentUserEntity>
  );
}

export default Main;
