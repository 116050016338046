import * as mandrill from "mandrill-api/mandrill";
import removeTags from "./removeTags";

export default async function handleEmail(formData, adminEmail, siteName) {
  return new Promise(function (resolve, reject) {
    const key = process.env.GATSBY_MANDRILL_API_KEY;
    if (!key || !adminEmail || !siteName) {
      reject();
    }
    const mandrill_client = new mandrill.Mandrill(key);
    var message = {
      html: `<ul>
    		<li>From: ${removeTags(formData.contact_name)}</li>
    		<li>Email: ${removeTags(formData.contact_email)}</li>
    		<li>Comment: ${removeTags(formData.contact_comment)}</li>
    	</ul>`,
      subject: `${formData.contact_subject}`,
      from_email: adminEmail,
      from_name: siteName,
      to: [
        {
          email: adminEmail,
          name: siteName,
          type: "to",
        },
      ],
      headers: {
        "Reply-To": adminEmail,
      },
      important: false,
      track_opens: null,
      track_clicks: null,
      auto_text: null,
      auto_html: null,
      inline_css: null,
      url_strip_qs: null,
      preserve_recipients: null,
      view_content_link: null,
      tracking_domain: null,
      signing_domain: null,
      return_path_domain: null,
      tags: ["contact-form-message"],
      metadata: {
        website: "ballpark.org",
      },
      attachments: [],
    };
    const opts = { message: message, async: true, ip_pool: "Main Pool" };
    mandrill_client.messages.send(opts, resolve, reject);
  });
}
