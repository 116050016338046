import React from "react";
import Main from "../../src/layouts/Main";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function PageWrap({ element, props }) {
  let pageTitle =
    props.data && props.data.allMarkdownRemark
      ? props.data.allMarkdownRemark.nodes[0].frontmatter.title
      : null;
  let backPath =
    props.data &&
    props.data.allMarkdownRemark &&
    props.data.allMarkdownRemark.nodes[0].frontmatter.slug
      ? props.data.allMarkdownRemark.nodes[0].frontmatter.slug
      : null;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
      <Main {...props} pageTitle={pageTitle} backPath={backPath}>
        {element}
      </Main>
    </GoogleReCaptchaProvider>
  );
}

export default PageWrap;
