import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0c2c56",
    },
    secondary: {
      main: "#005c5c",
      light: "#74a6a6",
    },
    tertiary: {
      main: "#729963",
      light: "#84ad74",
    },
    grey: {
      main: "#dadeda",
    },
    greyDark: {
      main: "#666",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#eee",
    },
  },
  typography: {
    fontFamily: "'Public Sans', Arial, Helvetica Neue, sans-serif",
    fontSize: 14,
    fontWeight: 300,
    fontWeightBold: "bold",
    headings: {
      fontFamily: "Glegoo, Helvetica Neue, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 1.3,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1600,
    },
  },
});

theme.typography.body1 = {
  fontSize: "1.1rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
};
theme.typography.h1 = {
  ...theme.typography.headings,
  fontSize: 44,
  letterSpacing: "-1px",
  [theme.breakpoints.down("md")]: {
    fontSize: 38,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 30,
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: 26,
  },
};

theme.typography.h2 = {
  ...theme.typography.headings,
  fontSize: 40,
  [theme.breakpoints.down("sm")]: {
    fontSize: 26,
  },
  "&:not(:first-child)": {
    paddingTop: theme.spacing(3),
  },
};

theme.typography.h3 = {
  fontSize: 22,
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
};

theme.typography.h4 = {
  ...theme.typography.headings,
  fontSize: 24,
  fontWeight: theme.typography.fontWeight,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
};

theme.typography.h5 = {
  fontSize: 18,
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
};

theme.typography.h6 = {
  fontSize: 20,
  fontWeight: theme.typography.fontWeight,
  [theme.breakpoints.down("md")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
};

export default theme;
