import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import arches_blu from "../images/arches-blu.png";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  header: {
    backgroundColor: "grey",
    backgroundImage: `url(${arches_blu})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    marginBottom: "0 !important",
    padding: "40px 0 0",
    position: "relative",
    backgroundBlendMode: "luminosity",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0 0",
    },
    "&.secondary": {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(2),
      },
    },
    "& > div": {
      position: "relative",
      zIndex: 2,
    },
    "& > span.blue-filter": {
      position: "absolute",
      display: "block",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.primary["main"],
    },
    "& > span.blue-filter-1": {
      zIndex: 1,
      mixBlendMode: "color",
      opacity: "1",
    },
    "& > span.blue-filter-2": {
      zIndex: 1,
      mixBlendMode: "multiply",
      opacity: "0.7",
    },
    "& > span.blue-filter-3": {
      zIndex: 1,
      mixBlendMode: "darken",
      opacity: "0.3",
    },
  },
  title: {
    color: "white",
    display: "block",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
    "&.secondary": {
      fontSize: "250%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "200%",
      },
      "& a": {
        textDecoration: "none",
        color: "inherit",
        "&:hover, &:active, &:focus": {
          textDecoration: "underline",
        },
      },
    },
  },
  back: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

function PageHeader({ pageTitle, backPath, background }) {
  const classes = useStyles();

  const header_class = `${classes.header} ${backPath !== null ? "secondary" : ""}`;

  const page_title_dom =
    backPath === null ? (
      pageTitle
    ) : (
      <Link to={`/${backPath}`} title="Go Back" className={classes.back}>
        {pageTitle}
      </Link>
    );

  const HeaderContent = ({ children }) => {
    return (
      <>
        {children}
        <span className="blue-filter blue-filter-1" />
        <span className="blue-filter blue-filter-2" />
        <span className="blue-filter blue-filter-3" />
      </>
    );
  };

  const HeaderWrapper = ({ children }) => {
    return backPath ? (
      <div
        className={header_class}
        style={background ? { backgroundImage: `url(${background})` } : {}}
      >
        <HeaderContent children={children} />
      </div>
    ) : (
      <header
        className={header_class}
        style={background ? { backgroundImage: `url(${background})` } : {}}
      >
        <HeaderContent children={children} />
      </header>
    );
  };

  return (
    <HeaderWrapper>
      <Container maxWidth="lg" className={classes.container}>
        <Typography
          id={pageTitle.toLowerCase() + "-page-title"}
          variant="h1"
          component={backPath ? "span" : "h1"}
          className={`${classes.title}  ${backPath !== null ? "secondary" : ""}`}
        >
          {page_title_dom}
        </Typography>
      </Container>
    </HeaderWrapper>
  );
}

export default PageHeader;
