import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";
import { slugify } from "../../util";
import Search from "./Search";

import logo from "../../images/logo.png";

const drawerWidth = 280;
const headerOffset = 160;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  brand: {
    flex: 1,
    position: "relative",
    zIndex: 4,
    "& a": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      position: "relative",
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.5),
      height: 100,
      [theme.breakpoints.down("md")]: {
        height: 70,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
      },
      "& img": {
        height: "100%",
        width: "auto",
        [theme.breakpoints.down("md")]: {
          transform: "translateX(-1rem)",
        },
      },
    },
  },
  toolbar: {
    padding: 0,
  },
  toolbarContainer: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    minHeight: 100,
    [theme.breakpoints.down("md")]: {
      minHeight: 70,
    },
  },
  toolbarRight: {
    display: "block",
    "& .MuiList-root": {
      "& + .MuiList-root": {
        paddingTop: 0,
      },
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 101,
    backgroundColor: "white",
  },
  appBarSubmenu: {
    zIndex: theme.zIndex.drawer + 102 + " !important",
  },
  navList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  secondaryNavList: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 0,
    marginBottom: 0,
    position: "relative",
    "& .sep": {
      display: "block",
      width: "150%",
      height: 1,
      backgroundImage:
        "linear-gradient(90deg, hsla(0, 0%, 100%, 0.52) 28%, rgba(33, 63, 101, 0.21))",
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& a": {
      color: grey["600"],
      "& .MuiListItem-root": {
        // paddingTop: 0,
        // paddingBottom: 0,
      },
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
  },
  linkText: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    border: "none",
    background: "transparent",
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    textTransform: "uppercase",
    "& .MuiTypography-body1": {
      fontSize: "0.9rem",
    },
  },
  ListItem: {
    padding: 0,
  },
  ListItemText: {
    padding: 0,
    "& a": {
      textDecoration: "none",
      color: "inherit",
      display: "block",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "&.nested a": {
      paddingLeft: theme.spacing(4),
      color: grey["600"],
    },
  },
  submenuItem: {
    padding: 0,
  },
  submenuLink: {
    textDecoration: "none",
    color: "inherit",
    display: "block",
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 100,
    [theme.breakpoints.down("md")]: {
      paddingTop: 70,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  IconButton: {
    width: 70,
    height: 70,
    marginRight: -theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: 50,
      height: 50,
    },
  },
}));

const getAnchorElements = (navData) => {
  const elements = {};
  for (const item of navData) {
    if (item.children) {
      let id = slugify(item.title);
      elements[id] = null;
      elements[`drawer-submenu-${id}`] = null;
    }
  }
  return elements;
};

function Navbar({ navData, secondaryNavData }) {
  const classes = useStyles();
  const [anchorElements, setAnchorElements] = React.useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleMenuClick = (e) => {
    const elmKey = e.currentTarget.getAttribute("aria-controls");
    const isFromDrawer = elmKey.includes("drawer");
    if (isFromDrawer) {
      if (Boolean(anchorElements[elmKey])) {
        setAnchorElements(getAnchorElements(navData));
      } else {
        setAnchorElements({ ...getAnchorElements(navData), [elmKey]: e.currentTarget });
      }
    } else {
      setAnchorElements({ ...getAnchorElements(navData), [elmKey]: e.currentTarget });
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAnchorLink = (path) => {
    if (typeof window !== "undefined") {
      let target = document.querySelector(`${path}`);
      if (target) {
        let offsetPosition = target.offsetTop - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const handleLinkClick = (e, path) => {
    if (
      path &&
      (path.charAt(0) === "#" || path === "/") &&
      typeof window !== "undefined"
    ) {
      e.preventDefault();
      if (path === "/") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        handleAnchorLink(path);
      }
    }
  };

  return (
    <Search>
      { toggleSearch => (
        <>
          <AppBar position="fixed" className={classes.appBar} color="transparent">
            <Toolbar className={classes.toolbar}>
              <Container maxWidth="lg" className={classes.toolbarContainer}>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={classes.brand}>
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Washington State Ballpark Public Facilities District"
                    />
                  </Link>
                </Typography>
                <NavDesktop
                  getAnchorElements={getAnchorElements}
                  setAnchorElements={setAnchorElements}
                  toggleSearch={toggleSearch}
                  anchorElements={anchorElements}
                  handleMenuClick={(e) => handleMenuClick(e)}
                  handleAnchorLink={handleAnchorLink}
                  handleLinkClick={handleLinkClick}
                  navData={navData}
                  secondaryNavData={secondaryNavData}
                  classes={classes}
                />
              </Container>
            </Toolbar>
          </AppBar>
          <NavMobile
            anchorElements={anchorElements}
            handleMenuClick={(e) => handleMenuClick(e)}
            mobileOpen={mobileOpen}
            setMobileOpen={setMobileOpen}
            navData={navData}
            secondaryNavData={secondaryNavData}
            classes={classes}
            handleDrawerToggle={handleDrawerToggle}
          />
        </>
      )}
    </Search>
  );
}

export default Navbar;
