import React from "react";
import { Link } from "gatsby";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { slugify } from "../../util";
// import IconButton from "@material-ui/core/IconButton";
// import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";

function NavMobile({
  classes,
  anchorElements,
  handleMenuClick,
  mobileOpen,
  navData,
  secondaryNavData,
  handleDrawerToggle,
  setMobileOpen,
}) {
  const container =
    typeof window !== "undefined" ? () => window.document.body : undefined;
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden lgUp>
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div>
            <div className={classes.toolbar} />
            <Divider />
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.root}
              key="prim-nav"
            >
              <>
                {navData.map(({ title, children, path }) => {
                  let id = slugify(title);
                  if (path) {
                    return (
                      <ListItem button key={id} className={classes.ListItem}>
                        <ListItemText className={classes.ListItemText}>
                          <Link to={path} onClick={() => setMobileOpen(false)}>
                            {title}
                          </Link>
                        </ListItemText>
                      </ListItem>
                    );
                  } else if (children) {
                    return (
                      <div key={id}>
                        <ListItem
                          button
                          aria-label={`Submenu of ${title}`}
                          aria-controls={`drawer-submenu-${id}`}
                          onClick={handleMenuClick}
                        >
                          <ListItemText primary={title} />
                          {Boolean(anchorElements[`drawer-submenu-${id}`]) ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={Boolean(anchorElements[`drawer-submenu-${id}`])}
                          timeout="auto"
                          unmountOnExit
                          id={`drawer-submenu-${id}`}
                        >
                          <List component="div" disablePadding>
                            {children.map(({ title, path }) => (
                              <ListItem
                                button
                                className={`${classes.nested} ${classes.ListItem}`}
                                key={title}
                              >
                                <ListItemText
                                  className={`${classes.ListItemText} nested`}
                                >
                                  <Link to={path} onClick={() => setMobileOpen(false)}>
                                    {title}
                                  </Link>
                                </ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </>
            </List>
            <Divider />
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.root}
              key="sec-nav"
            >
              <>
                {secondaryNavData.map(({ title, path, key }) => {
                  let id = slugify(title);
                  if (path) {
                    return (
                      <ListItem button key={id} className={classes.ListItem}>
                        <ListItemText className={classes.ListItemText}>
                          <Link to={path} onClick={() => setMobileOpen(false)}>
                            {key === "user" && (
                              <PersonIcon
                                style={{ verticalAlign: "middle", marginRight: 10 }}
                              />
                            )}
                            {title}
                          </Link>
                        </ListItemText>
                      </ListItem>
                    );
                  } else {
                    return <></>;
                  }
                })}
                {/* <IconButton aria-label="search" component="a">
                  <SearchIcon />
                </IconButton> */}
              </>
            </List>
          </div>
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavMobile;
