// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-list-meeting-js": () => import("./../../../src/layouts/ListMeeting.js" /* webpackChunkName: "component---src-layouts-list-meeting-js" */),
  "component---src-layouts-list-news-js": () => import("./../../../src/layouts/ListNews.js" /* webpackChunkName: "component---src-layouts-list-news-js" */),
  "component---src-layouts-list-tag-js": () => import("./../../../src/layouts/ListTag.js" /* webpackChunkName: "component---src-layouts-list-tag-js" */),
  "component---src-layouts-single-knowledge-base-article-js": () => import("./../../../src/layouts/SingleKnowledgeBaseArticle.js" /* webpackChunkName: "component---src-layouts-single-knowledge-base-article-js" */),
  "component---src-layouts-single-meeting-js": () => import("./../../../src/layouts/SingleMeeting.js" /* webpackChunkName: "component---src-layouts-single-meeting-js" */),
  "component---src-layouts-single-news-js": () => import("./../../../src/layouts/SingleNews.js" /* webpackChunkName: "component---src-layouts-single-news-js" */),
  "component---src-layouts-single-page-js": () => import("./../../../src/layouts/SinglePage.js" /* webpackChunkName: "component---src-layouts-single-page-js" */),
  "component---src-layouts-single-personnel-js": () => import("./../../../src/layouts/SinglePersonnel.js" /* webpackChunkName: "component---src-layouts-single-personnel-js" */),
  "component---src-layouts-single-resource-type-js": () => import("./../../../src/layouts/SingleResourceType.js" /* webpackChunkName: "component---src-layouts-single-resource-type-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-board-js": () => import("./../../../src/pages/board.js" /* webpackChunkName: "component---src-pages-board-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

