import React from "react";
import { navigate } from "gatsby";
import { grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Collapse from '@material-ui/core/Collapse';
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  filters: {
    backgroundColor: grey["300"],
    overflow: "hidden",
    padding: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: "100%",
    margin: 0,
  },
  submitButton: {
    lineHeight: "3"
  }
}));

const Search = ({children}) => {
  const [opened, openSearch] = React.useState(false);

  const classes = useStyles();

  const toggleSearch = () => {  
    if (window.location.pathname != "/search/") {
      openSearch(!opened);
    } else {
      window.location.href = "#";
      window.location.href = "#search-page-title"; 
    }
  }

  const handleSearch = () => {
    let searchTerm = document.querySelector('#search-term');
    if (searchTerm && searchTerm.value) {
      navigate('/search/?q=' + searchTerm.value);
      toggleSearch();
    }
  }

  const handleEnter = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      handleSearch();
    }
  }

  return ( 
    <>
      <Collapse in={opened}>
        <form noValidate autoComplete="off" className={classes.filters}>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="search-term"
                  label="Search..."
                  variant="outlined"
                  defaultValue=""
                  className={classes.formControl}
                  onKeyDown={e => handleEnter(e)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button className={classes.submitButton} onClick={e => handleSearch()}>Submit</Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      </Collapse>
      {children(toggleSearch)}
    </>
  )
}

export default Search
