export default (nodes) => {
  let merged = [];
  for (const data of nodes) {
    if (data.nodes && data.nodes.length > 0) {
      for (const item of data.nodes) {
        merged.push({
          nid: item.drupal_internal__nid,
          slug: item.fields.slug,
        });
      }
    }
  }
  return merged;
};
