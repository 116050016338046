import React, { useContext } from "react";
import { Entity, UserContext } from "@parallelpublicworks/entitysync";

export default function CurrentUserEntity({ children, ...props }) {
  const [userState] = useContext(UserContext);
  return (
    <>
      {userState && userState.currentUserId ? (
        <Entity {...props} source={{ id: userState.currentUserId }}>
          {children}
        </Entity>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
